
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State, Mutation, Getter } from 'vuex-class';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import { cloneDeep, filter, find, findIndex, map, orderBy } from 'lodash';
import moment from 'moment-timezone';
import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SidePanel from '@/components/nav/SidePanel.vue';
import DeviceRenameModal from '@/components/devices/DeviceRenameModal.vue';
import DeviceNodVehLinkModal from '@/components/devices/DeviceNodVehLinkModal.vue';
import PreviewScreenshot from '@/components/devices/PreviewScreenshot.vue';
import DeviceNoLoadingModeSettings from '@/components/devices/DeviceNoLoadingModeSettings.vue';
import CloseButton from '@/components/CloseButton.vue';
import { DeviceUserConfig } from '@/types/index.d';
import {
  deleteDevice,
  putDeviceUserConfig,
  linkDeviceWithScreen,
  getVehicle,
  unlinkOnBoard,
  resetDevice,
} from '@/services/axios';
import { GOOGLE_MAPS_TOKEN } from '@/services/config';
import { mapConfig } from '@/services/maps';

@Component({
  components: {
    SectionHeading,
    SectionH1,
    SidePanel,
    DeviceRenameModal,
    DeviceNodVehLinkModal,
    PreviewScreenshot,
    CloseButton,
    LMap,
    LTileLayer,
    LMarker,
    Vue2LeafletGoogleMutant,
    DeviceNoLoadingModeSettings,
  },
})
export default class DevicesScreenshotsView extends Vue {
  isShowingDeviceRenameModal: boolean = false;
  isShowingDeviceLinkModal: boolean = false;
  isShowingPreviewShotModal: boolean = false;
  refreshTimeout: any = null;
  isFetchingDevice: boolean = false;
  selectedDevice: DeviceUserConfig | null = null;
  selectedScreenId: any = null;
  selectedVehicle: any | null = null;
  sortBySelected: string = 'name';

  @Action loadDevices!: () => any;

  @State devices!: DeviceUserConfig[];
  @State opId!: number;
  @State screens!: any[];
  @State vehicles!: any[];
  @State operators!: any[];
  @Getter noLoadingModeAvailable!: boolean;

  @Mutation setDevices!: (devices: any) => void;

  refreshInterval() {
    this.isFetchingDevice = true;
    this.refreshTimeout = setTimeout(() => {
      this.loadDevices().finally(() => {
        this.isFetchingDevice = false;
        this.refreshInterval();
      });
    }, 1000 * 30);
  }
  setSelectedVehicle() {
    this.selectedVehicle = null;
    if (
      this.selectedDevice &&
      this.selectedDevice.on_board &&
      this.selectedDevice.on_board.vhc_id &&
      this.selectedDevice.on_board.vhc_op_id
    ) {
      const vhc_id = this.selectedDevice.on_board.vhc_id;
      const vhc_op_id = this.selectedDevice.on_board.vhc_op_id;
      getVehicle(vhc_op_id, vhc_id).then((res: any) => {
        this.selectedVehicle = res.data;
      });
    }
  }
  isDeviceOnline(device: DeviceUserConfig): boolean {
    if (device.online_at) {
      let diff = moment().diff(moment(`${device.online_at}Z`), 'minutes');
      if (diff <= 15) {
        return true;
      }
    }
    return false;
  }
  isDeviceHDMIError(device: DeviceUserConfig): boolean {
    return this.isDeviceOnline(device) && !device.is_hdmi_turn_on;
  }
  getLastToString(date: Date) {
    const dateMoment = moment(`${date}Z`);

    if (dateMoment.isValid()) {
      return dateMoment.locale('th').format('DD/MM/YY, HH:mm');
    }
    return null;
  }
  getLastFromNow(date: Date) {
    const dateMoment = moment(`${date}Z`);

    if (dateMoment.isValid()) {
      return dateMoment.locale('th').fromNow();
    }
    return null;
  }
  updateDeviceState() {
    if (this.selectedDevice) {
      let newDevices = cloneDeep(this.devices);
      newDevices[
        findIndex(newDevices, ['usr_id', this.selectedDevice.usr_id])
      ] = this.selectedDevice;
      this.setDevices(newDevices);
    }
  }
  updateSelectedDevice() {
    const device = this.devices.find(device => device.usr_id === this.selectedDevice!.usr_id);
    this.handleSelectDevice(device || null);
  }
  linkScreen() {
    if (
      this.selectedDevice &&
      this.selectedDevice.name &&
      this.selectedDevice.op_id &&
      this.selectedDevice.usr_id &&
      this.selectedScreenId
    ) {
      const preparedDevice = {
        name: this.selectedDevice.name,
        scrn_id: this.selectedScreenId,
        is_act: true,
      };

      if (this.selectedDevice.scrn_id === null) {
        const body = {
          cfg: [
            {
              usr_id: this.selectedDevice.usr_id,
              scrn_id: this.selectedScreenId,
              is_act: true,
            },
          ],
        };
        linkDeviceWithScreen(this.selectedDevice.op_id, body)
          .then(() => {
            this.selectedDevice!.scrn_id = this.selectedScreenId;
            this.updateDeviceState();
          })
          .catch(() => {
            alert('เลือกหน้าจอไม่สำเร็จ กรุณาลองใหม่');
            this.selectedScreenId = this.selectedDevice!.scrn_id || null;
          });
      } else {
        putDeviceUserConfig(this.selectedDevice.op_id, this.selectedDevice.usr_id, preparedDevice)
          .then(() => {
            this.selectedDevice!.scrn_id = this.selectedScreenId;
            this.updateDeviceState();
          })
          .catch(() => {
            alert('เลือกหน้าจอไม่สำเร็จ กรุณาลองใหม่');
            this.selectedScreenId = this.selectedDevice!.scrn_id || null;
          });
      }
    }
  }
  handleSelectDevice(device: DeviceUserConfig | null) {
    this.selectedDevice = device;
    this.selectedScreenId = null;
    this.setSelectedVehicle();

    if (device && device.scrn_id) {
      this.selectedScreenId = device.scrn_id;
    }
  }
  handleDeleteDevice(): void {
    if (!this.selectedDevice) return;

    if (window.confirm('คุณต้องการลบอุปกรณ์นี้หรือไม่?')) {
      clearTimeout(this.refreshTimeout);
      deleteDevice(this.opId, this.selectedDevice.usr_id)
        .then(() => {
          this.loadDevices();
          this.selectedDevice = null;
        })
        .finally(() => {
          this.refreshInterval();
        });
    }
  }

  handleResetDevice(usr_id: number): void {
    if (
      window.confirm(
        usr_id ? 'คุณต้องการรีเซ็ตอุปกรณ์นี้หรือไม่?' : 'คุณต้องการรีเซ็ตอุปกรณ์ทั้งหมดหรือไม่'
      )
    ) {
      const deviceUserIds = usr_id ? usr_id : map(this.devices, 'usr_id');
      resetDevice(this.opId, deviceUserIds)
        .then(() => {
          alert('รีเซ็ตสำเร็จแล้ว');
        })
        .catch(() => {
          alert('รีเซ็ตไม่สำเร็จแล้ว กรุณาลองใหม่');
        });
    }
  }
  openDeviceRenameModal(): void {
    this.isShowingDeviceRenameModal = true;
  }
  closeDeviceRenameModal(): void {
    this.isShowingDeviceRenameModal = false;
    this.loadDevices().then(() => {
      this.updateSelectedDevice();
    });
  }
  openDeviceLinkModal(): void {
    this.isShowingDeviceLinkModal = true;
  }
  closeDeviceNodVehLinkModal(): void {
    this.isShowingDeviceLinkModal = false;
  }
  openPreviewScreenshot(): void {
    this.isShowingPreviewShotModal = true;
  }
  closePreviewScreenshot(): void {
    this.isShowingPreviewShotModal = false;
  }
  handleUnlinkOnBoard() {
    if (this.selectedDevice && this.selectedDevice.op_id && this.selectedDevice.usr_id) {
      unlinkOnBoard(this.selectedDevice.op_id, this.selectedDevice.usr_id)
        .then(() => {
          this.selectedVehicle = null;
          this.loadDevices().then(() => {
            this.updateSelectedDevice();
          });
        })
        .catch(() => {
          alert('Unlink ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
        });
    }
  }
  onVehicleChanged(vehicle: any) {
    this.selectedVehicle = vehicle;
    this.loadDevices().then(() => {
      this.updateSelectedDevice();
    });
  }

  setDeviceNoLoadingMode(isEnabled: boolean) {
    if (this.selectedDevice) {
      this.selectedDevice.no_loading_mode_enable = isEnabled;
      this.updateDeviceState();
    }
  }

  get sortedDevices() {
    const orders: { [key: string]: any } = {
      name: 'asc',
      online_at: 'desc',
    };

    return orderBy(this.devices, this.sortBySelected, orders[this.sortBySelected]);
  }

  get apiKey(): string {
    return GOOGLE_MAPS_TOKEN;
  }

  get mapOptions(): any {
    return mapConfig;
  }
  get icon() {
    return L.divIcon({
      html:
        '<div style="display: flex; align-items: center; justify-content: center; font-size: 13px; font-weight: 600; color: white; width: 24px; background: #4d6aff; height: 24px; border-radius: 12px; margin-left: -6px; margin-top: -6px;" />',
      popupAnchor: [0, -12], //changed popup position
    });
  }
  get status() {
    return this.$route.query.status || 'all';
  }
  get onlineDevices() {
    return filter(this.sortedDevices, (device: DeviceUserConfig) => this.isDeviceOnline(device));
  }
  get offlineDevices() {
    return filter(this.sortedDevices, device => !this.isDeviceOnline(device));
  }
  get hdmiErrorDevices() {
    return filter(this.sortedDevices, device => this.isDeviceHDMIError(device));
  }
  get filteredDevices() {
    if (this.status === 'online') return this.onlineDevices;
    if (this.status === 'offline') return this.offlineDevices;
    if (this.status === 'hdmi-error') return this.hdmiErrorDevices;

    return this.sortedDevices;
  }
  get totalDevice() {
    return this.devices.length || 0;
  }
  get totalDeviceOnline() {
    return this.onlineDevices.length || 0;
  }
  get totalDeviceOffline() {
    return this.offlineDevices.length || 0;
  }
  get totalDeviceHdmiError() {
    return this.hdmiErrorDevices.length || 0;
  }
  get mapCenter() {
    if (this.selectedDevice && this.selectedDevice.lat && this.selectedDevice.lng) {
      return [this.selectedDevice.lat, this.selectedDevice.lng];
    }
    return [13.724043632799845, 100.52558898925783];
  }
  get vehicleOperatorName() {
    if (this.selectedVehicle) {
      const op = find(this.operators, { op_id: this.selectedVehicle.op_id });
      if (op) {
        return op.i18n_name || op.dflt_name || '';
      }
    }
    return null;
  }
  get linkedVehicleName(): string {
    if (
      (this.selectedDevice &&
        this.selectedDevice.on_board &&
        this.selectedDevice.on_board.vhc_id) ||
      this.selectedVehicle
    ) {
      if (this.selectedVehicle) {
        return `${this.selectedVehicle.op_vhc_id} (${this.selectedVehicle.license})`;
      }
      return `เชื่อมต่อแล้ว (ไม่พบรถ)`;
    }
    return 'ยังไม่ได้เชื่อมต่อ';
  }

  get contentRightMargin() {
    return this.selectedDevice ? '406px' : '0px';
  }

  @Watch('selectedScreenId')
  onSelectedScreenChanged() {
    if (this.selectedDevice!.scrn_id !== this.selectedScreenId) {
      this.linkScreen();
    }
  }
  @Watch('$route')
  onRouteChanged() {
    this.selectedDevice = null;
  }

  mounted() {
    this.refreshInterval();
  }
}
